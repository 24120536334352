<template>
    <div class="home">
        <div class="white-bg">
            <div class="baccount-tit">
                <h3 class="g-title">账户安全</h3>
            </div>
            <div class="zpanle modifyp-panle">
                <div class="account-hd">
                    <div class="account-cnt">
                        <span class="pic-box">
                            <img :src="$img.chphone">
                        </span>
                        <p class="accont-slogin">绑定手机</p>
                    </div>
                </div>
                <div class="panner-bd modifypd">
                    <el-form
                        :model="accountForm"
                        status-icon
                        :rules="rules"
                        ref="accountForm"
                        label-width="100px"
                        class="g-form"
                    >
                        <el-form-item
                            label="手机号码"
                            prop="new_mobile"
                        >
                            <el-input
                                type="text"
                                v-model="accountForm.new_mobile"
                                auto-complete="off"
                            ></el-input>
                        </el-form-item>
                        <el-form-item
                            label="验证码"
                            prop="code"
                            name="code"
                        >
                            <el-input
                                class="w160"
                                placeholder="请输入短信验证码"
                                v-model="accountForm.code"
                            ></el-input>
                            <el-button
                                class="dx-code"
                                @click="getMobileCode"
                            >{{time==0?'获取验证码':time+'秒后获取'}}</el-button>
                        </el-form-item>
                        <el-form-item class="btn-wrap">
                            <div class="btn-cont">
                                <el-button
                                    type="primary"
                                    @click="handleSubmit()"
                                >确认绑定</el-button>
                            </div>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "bindMphone",
    metaInfo() {
        return {
            title: "绑定手机号码 - 询龙网",
            meta: [
                {
                    name: "keywords",
                    content: ""
                }, {
                    name: "description",
                    content: ''
                }
            ]
        }
    },
    data() {
        let _this = this;
        return {
            accountForm: {
                new_mobile: '',
                mobile: '',
                code: '',
                type: 2,
                action: 2,
            },
            time: 0,
            timer: null,
            rules: {},
            codeRules: {
                new_mobile: [
                    {
                        required: true,
                        message: "请输入联系电话",
                        trigger: "blur"
                    },
                    {
                        validator: _this.validata.mobile(),
                        trigger: ["change", "blur"]
                    }
                ]
            },
            formRules: {
                new_mobile: [
                    {
                        required: true,
                        message: "请输入联系电话",
                        trigger: "blur"
                    },
                    {
                        validator: _this.validata.mobile(),
                        trigger: ["change", "blur"]
                    }
                ],
                code: [
                    {
                        required: true,
                        message: "请输入短信验证码",
                        trigger: "blur"
                    }
                ],
            },
        };
    },
    methods: {
        getMobileCode() {
            this.rules = this.codeRules;
            setTimeout(() => {
                this.$refs["accountForm"].validate(valid => {
                    if (valid && this.time == 0) {
                        this.accountForm.mobile = this.accountForm.new_mobile
                        this.post(
                            "/user/user/getcode",
                            this.accountForm,
                            data => {
                                if (200 !== data.code) {
                                    this.$message.error(data.msg);
                                    return;
                                }
                                this.time = 60;
                                this.startTimer();
                                if (data.data) {
                                    this.$message({ message: data.data, duration: 3000 });
                                }
                            }
                        );
                    }
                });
            }, 1000);
        },
        startTimer() {
            let _this = this;
            if (!_this.timer) {
                _this.timer = setInterval(() => {
                    if (_this.time > 0) {
                        _this.time--;
                        sessionStorage.setItem("bindMphoneTime", _this.time);
                    } else {
                        clearInterval(_this.timer);
                        _this.timer = null;
                        sessionStorage.removeItem("bindMphoneTime");
                    }
                }, 1000);
            }
        },
        handleSubmit() {
            this.rules = this.formRules;
            this.$refs["accountForm"].validate((valid, validInfoObj) => {
                if (valid) {
                    this.post(
                        "/user/user/changeinfo",
                        this.accountForm,
                        data => {
                            if (data.code != 200) {
                                this.$message.error(data.msg);
                                return;
                            }
                            this.$message.success(data.msg);
                            this.$router.push({ name: 'accountSafe' });
                        }
                    );
                }
            });
        },

    },
    created() {
        let _time = sessionStorage.getItem("bindMphoneTime");
        if (_time) {
            this.time = _time;
            this.startTimer();
        }
    }
};
</script>
<style lang="less" scoped>
@import "../../../styles/account.less";
</style>


